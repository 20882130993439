/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Layout, Menu, Dropdown, Avatar, Badge } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
  GlobalOutlined,
  BankOutlined,
  TeamOutlined,
  AreaChartOutlined,
  FormOutlined,
  BookOutlined,
  TrophyOutlined,
  BellFilled,
} from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useNotificationData } from '~/hooks';
import './styles.scss';
import { setAdvancedSearchFilters } from '~/store/ducks/people';

import rotas from '~/constants/paths';
import { signOut } from '~/store/fetchActions/fetchAuth';
import { selectUserProfile } from '~/store/selectors';
import { getRole } from '~/config/environment';
import { APP_NAME } from '~/constants/app';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const Authenticated = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const advancedSearch = useSelector(state => state.people);
  const { pathname } = useLocation();
  const {
    usersNotifications,
    costCentersNoBu,
    costCentersNoGroup,
    towersNoBu,
    suggestions,
    calcIndex,
  } = useNotificationData();
  const [collapsed, setCollapsed] = useState(false);
  const user = useSelector(selectUserProfile);

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => dispatch(signOut(history))}
        icon={<LogoutOutlined />}
      >
        Deslogar
      </Menu.Item>
    </Menu>
  );

  const menuNotificationBadges = (
    <Menu className="notification">
      <h2>Notificações</h2>

      {costCentersNoBu?.length > 0 && (
        <Link to="/practices">
          <div>
            <p>{costCentersNoBu?.length} Centro de Custos sem BUs</p>
          </div>
        </Link>
      )}

      {costCentersNoGroup?.length > 0 && (
        <Link to="/towers/register">
          <div>
            <p>{costCentersNoGroup?.length} Centro de Custos sem Torres</p>
          </div>
        </Link>
      )}

      {towersNoBu > 0 && (
        <Link
          to={{ pathname: '/soluters' }}
          onClick={() =>
            dispatch(
              setAdvancedSearchFilters({
                ...advancedSearch,
                notAllocated: true,
              }),
            )
          }
        >
          <div>
            <p>{towersNoBu} Usuários sem Torres</p>
          </div>
        </Link>
      )}

      {suggestions?.length > 0 && (
        <Link to="/skills">
          <div>
            <p>{suggestions?.length} Habilidades sugeridas</p>
          </div>
        </Link>
      )}

      {calcIndex() === 0 && <p>Sem notificações </p>}
    </Menu>
  );

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo-header">
          <img
            src="/images/logo.png"
            className="logo"
            alt={`Logo do ${APP_NAME}`}
          />
          <div className={`title-header ${collapsed ? '' : 'active'}`}>
            <p>Passaporte Solutis</p>
            <p>
              <b>{APP_NAME}</b>
            </p>
          </div>
        </div>
        <Menu theme="dark" mode="inline" selectedKeys={[pathname]}>
          <Menu.Item
            style={{ marginTop: 0 }}
            key={rotas.SOLUTERS.LIST}
            icon={<TeamOutlined />}
          >
            <Link to={rotas.SOLUTERS.LIST}>Soluters</Link>
          </Menu.Item>
          {getRole() === 'admin' && (
            <Menu.Item key={rotas.BADGES} icon={<TrophyOutlined />}>
              <Link to={rotas.BADGES.DASHBOARD}>Badges</Link>
            </Menu.Item>
          )}
          <Menu.Item key={rotas.COMPETENCES} icon={<BookOutlined />}>
            <Link to={rotas.COMPETENCES}>Certificações</Link>
          </Menu.Item>
          {getRole() === 'admin' && (
            <Menu.Item key={rotas.DISTRICTS.LIST} icon={<GlobalOutlined />}>
              <Link to={rotas.DISTRICTS.LIST}>Distritos</Link>
            </Menu.Item>
          )}
          <SubMenu key="sub-towers" icon={<BankOutlined />} title="Torres">
            <Menu.Item key={rotas.TOWER.REGISTER}>
              <Link to={rotas.TOWER.REGISTER}>Mapa</Link>
            </Menu.Item>
            <Menu.Item key={rotas.TOWER.SCHEDULE}>
              <Link to={rotas.TOWER.SCHEDULE}>Planejamento</Link>
            </Menu.Item>
            <Menu.Item key={rotas.TOWER.CAPACITY}>
              <Link to={rotas.TOWER.CAPACITY}>Capacity</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub2" icon={<AreaChartOutlined />} title="Visões">
            <Menu.Item key={rotas.DASHBORD_BI}>
              <Link to={rotas.DASHBORD_BI}>Dashboard(BI)</Link>
            </Menu.Item>
            <Menu.Item key={rotas.INDICATORS.MOOD}>
              <Link to={rotas.INDICATORS.MOOD}>Mood</Link>
            </Menu.Item>
            <Menu.Item key={rotas.INDICATORS.GENERAL}>
              <Link to={rotas.INDICATORS.GENERAL}>Geral</Link>
            </Menu.Item>
            <Menu.Item key={rotas.INDICATORS.TOWERS}>
              <Link to={rotas.INDICATORS.TOWERS}>Torres</Link>
            </Menu.Item>
          </SubMenu>
          {getRole() === 'admin' && (
            <>
              <Menu.Item
                style={{ marginTop: 0 }}
                key={rotas.INTERVIEWS}
                icon={<FormOutlined />}
              >
                <Link to={rotas.INTERVIEWS}>Entrevistas</Link>
              </Menu.Item>
              <SubMenu
                key="sub3"
                icon={<SettingOutlined />}
                title="Configurações"
              >
                <Menu.Item key={rotas.EXPERIENCES} style={{ marginTop: 0 }}>
                  <Link to={rotas.EXPERIENCES}>Experiências</Link>
                </Menu.Item>
                <Menu.Item key={rotas.SKILLS} style={{ marginTop: 0 }}>
                  <Link to={rotas.SKILLS}>Habilidades</Link>
                </Menu.Item>
                <Menu.Item key={rotas.ROLES} style={{ marginTop: 0 }}>
                  <Link to={rotas.ROLES}>Papéis</Link>
                </Menu.Item>
                <Menu.Item key={rotas.PRACTICES}>
                  <Link to={rotas.PRACTICES}>BUs</Link>
                </Menu.Item>
                <Menu.Item key={rotas.JOURNEY}>
                  <Link to={rotas.JOURNEY}>Famílias e Cargos</Link>
                </Menu.Item>
                <Menu.Item key={rotas.MOOD.TAGS}>
                  <Link to={rotas.MOOD.TAGS}>Tags do Mood</Link>
                </Menu.Item>
                <Menu.Item key={rotas.USEFUL_LINKS}>
                  <Link to={rotas.USEFUL_LINKS}>Links Úteis</Link>
                </Menu.Item>
              </SubMenu>
            </>
          )}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background header"
          style={{ padding: 0 }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: 'trigger',
              onClick: () => setCollapsed(!collapsed),
            },
          )}
          <div>
            <Dropdown overlay={menuNotificationBadges} trigger={['click']}>
              <Badge count={calcIndex()}>
                <Avatar
                  size="large"
                  style={{
                    marginRight: 10,
                    fontSize: '18px',
                    cursor: 'pointer',
                  }}
                  src={<BellFilled />}
                />
              </Badge>
            </Dropdown>
            <span
              style={{
                color: 'white',
                marginRight: 20,
                fontSize: 16,
              }}
            >
              {user?.name}
            </span>
            <Dropdown overlay={menu} trigger={['click']}>
              <Avatar
                size="large"
                style={{ marginRight: 10, cursor: 'pointer' }}
                src={user?.photo || <UserOutlined />}
                alt="Foto do Usuário Logado"
              />
            </Dropdown>
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

const Anonymous = ({ children }) => (
  <Layout>
    <Content style={{ minHeight: '100vh' }}>{children}</Content>
  </Layout>
);

export default { Authenticated, Anonymous };
