import React, { useCallback, useEffect, useState } from 'react';

import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Upload,
  Typography,
  Select,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { SelectSearchPeople } from '~/components';
import { getPeopleTower } from '~/store/fetchActions/fetchTowers';
import { downloadCompetenceFile, getCompetenceFileUrl } from '../helpers/files';

const { Text } = Typography;
const { Dragger } = Upload;
const { Option } = Select;

const ManageCompetenceForm = React.memo(
  ({ handleSubmit, competence, editing, formId }) => {
    const [createAnotherItem, setCreateAnotherItem] = useState(false);
    const [competenceFileList, setCompetenceFileList] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
      if (form && competence) {
        const { user, id: competenceId } = competence;
        dispatch(getPeopleTower(user));

        const files = competence.filename
          ? [
              {
                name: competence.filename,
                url: getCompetenceFileUrl(competenceId),
                isServerSide: true,
              },
            ]
          : [];

        form.setFieldsValue({
          ...competence,
          files,
          date: moment(competence.date),
          expirationDate: competence.expirationDate
            ? moment(competence.expirationDate)
            : undefined,
        });

        if (files.length) setCompetenceFileList(files);
      }
    }, [form, competence, dispatch]);

    const handleSubmitForm = useCallback(
      values => {
        handleSubmit(
          { ...values, files: competenceFileList },
          !createAnotherItem,
        ).then(() => {
          form.resetFields();
          setCompetenceFileList([]);
        });
      },
      [form, createAnotherItem, competenceFileList, handleSubmit],
    );

    const handlePersonChange = useCallback(
      email =>
        form.setFieldsValue({
          userEmail: email,
        }),
      [form],
    );

    const handleClickUpload = useCallback(({ fileList }) => {
      setCompetenceFileList(fileList);
    }, []);

    const handleCreateAnotherItemChange = useCallback(e => {
      setCreateAnotherItem(e.target.checked);
    }, []);

    const disabledDate = current => {
      return current && current._d > new Date();
    };

    return (
      <Form
        layout="vertical"
        data-testid="add-competence-form"
        onFinish={handleSubmitForm}
        id={formId}
        form={form}
      >
        <Form.Item
          label="Soluter / Matrícula:"
          name="userEmail"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          required
        >
          <SelectSearchPeople
            customStyle={{ minWidth: 300, marginBottom: 0 }}
            testId="select-soluter-competences"
            onChange={handlePersonChange}
          />
        </Form.Item>
        <Form.Item
          label="Título:"
          name="title"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descrição:"
          name="description"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Link:"
          name="link"
          rules={[{ type: 'url', message: 'Insira um link válido' }]}
        >
          <Input />
        </Form.Item>
        <Row justify="space-between">
          <Col>
            <Form.Item
              label="Obtida em:"
              name="date"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
              required
            >
              <DatePicker
                format="DD/MM/YYYY"
                style={{ width: '200px' }}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Expira em:" name="expirationDate">
              <DatePicker format="DD/MM/YYYY" style={{ width: '200px' }} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Status"
          name="valid"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          required
        >
          <Select
            placeholder="Selecione um Status"
            filterOption={false}
            allowClear
            data-testid="competence-select"
            style={{ width: 250 }}
          >
            <Option data-testid="select-option-or" value="VALIDADO">
              Validado
            </Option>
            <Option data-testid="select-option-and" value="INVALIDADO">
              Invalidado
            </Option>
            <Option data-testid="select-option-and" value="PENDENTE DE ANÁLISE">
              Pendente de Análise
            </Option>
            <Option data-testid="select-option-and" value="PENDENTE ARQUIVO">
              Pendente Arquivo
            </Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Dragger
            fileList={competenceFileList}
            onChange={handleClickUpload}
            beforeUpload={() => false}
            isImageUrl={() => false}
            onPreview={() =>
              editing &&
              downloadCompetenceFile(competence.filename, competence.id)
            }
            listType="picture"
            maxCount={1}
            style={{ marginTop: 20 }}
            locale={{
              removeFile: 'Remover arquivo',
            }}
            accept="application/pdf"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Selecione um arquivo para esta competência
            </p>
            <p className="ant-upload-hint">
              Arraste para cá ou clique para adicionar um arquivo
            </p>
            <p className="ant-upload-hint">
              Apenas arquivos PDF são suportados.
            </p>
          </Dragger>
        </Form.Item>
        {editing && (
          <Text type="secondary">
            Ultima alteração: {competence?.registerBy} em {competence?.updateAt}
            .
          </Text>
        )}
        {!editing && (
          <Checkbox
            onChange={handleCreateAnotherItemChange}
            checked={createAnotherItem}
          >
            Criar outro item
          </Checkbox>
        )}
      </Form>
    );
  },
);

export default ManageCompetenceForm;

ManageCompetenceForm.propTypes = {
  competence: PropTypes.objectOf(PropTypes.any),
  formId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  editing: PropTypes.bool,
};

ManageCompetenceForm.defaultProps = {
  competence: undefined,
  editing: false,
};
