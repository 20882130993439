import { UserOutlined, CrownFilled } from '@ant-design/icons';
import { List, Avatar, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '~/components';
import { getRole } from '~/config/environment';
import * as fetchTowers from '~/store/fetchActions/fetchTowers';
import './styles.scss';

export default function ListProjectMembers({ list, project, currentYear }) {
  const dispatch = useDispatch();

  const handleDelete = async allocationId => {
    try {
      await fetchTowers.removeTeamMember(project, allocationId);
      dispatch(
        fetchTowers.getTowerProjects(project.groupId, true, currentYear),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <div>
      {list?.length > 0 ? (
        <List
          itemLayout="horizontal"
          dataSource={list}
          pagination={{
            position: 'bottom',
            pageSize: 4,
            hideOnSinglePage: true,
          }}
          renderItem={item => (
            <List.Item
              actions={[
                <Button
                  handleDelete={() => {
                    handleDelete(item._id);
                  }}
                  tooltipIdentifier="Soluter da Equipe"
                  title={item.person}
                  id={item.id}
                  type="delete"
                />,
              ]}
            >
              <List.Item.Meta
                avatar={
                  !item?.photo ? (
                    <Skeleton.Avatar
                      loading={!item?.photo}
                      avatar
                      active={!item?.photo}
                    />
                  ) : (
                    <Avatar src={item?.photo} icon={<UserOutlined />} />
                  )
                }
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {getRole() === 'admin' && item.personKey ? (
                      <Link to={`/soluters/${item.personKey}`}>
                        {item.person}
                      </Link>
                    ) : (
                      <p className="no-margin soluter-name">{item.person}</p>
                    )}
                    {item.isLeader && (
                      <CrownFilled
                        style={{ marginLeft: 5, color: 'rgb(255, 180, 4)' }}
                      />
                    )}
                  </div>
                }
                description={`${item.mainRole ? item.mainRole : '-'} ${
                  item.technology ? `• ${item.technology}` : ''
                }`}
              />
            </List.Item>
          )}
        />
      ) : (
        <p>Este projeto ainda não possui membros.</p>
      )}
    </div>
  );
}

ListProjectMembers.propTypes = {
  list: PropTypes.array.isRequired,
  project: PropTypes.object,
  currentYear: PropTypes.string,
};

ListProjectMembers.defaultProps = {
  project: {},
  currentYear: '',
};
