import { Col, Row, Typography, Rate, Drawer } from 'antd';
import PropTypes from 'prop-types';
import { people } from '~/constants';
import './styles.scss';

const { Text, Paragraph } = Typography;

export default function ExitInterviewDetails({
  interview,
  isModalVisible,
  setModalVisible,
}) {
  return (
    <Drawer
      visible={isModalVisible}
      onClose={() => setModalVisible(false)}
      width="65vw"
      title={`Entrevista de Desligamento: ${interview?.interviewDate}`}
    >
      <Row>
        <Paragraph>Data do Desligamento: {interview?.exitDate}</Paragraph>
      </Row>
      <Row className="interview">
        <Col span={12}>
          <Text strong>1. Nome completo</Text>
          <Paragraph className="interview__answer">{interview?.name}</Paragraph>
          <Text strong>Nome do Entrevistador</Text>
          <Paragraph className="interview__answer">{interview?.BP}</Paragraph>
          <Text strong>BU:</Text>
          <Paragraph className="interview__answer">
            {interview?.practice}
          </Paragraph>
          <Text strong>Centro de custo:</Text>
          <Paragraph className="interview__answer">
            {interview?.team || 'Não identificado'}
          </Paragraph>
          <Text strong>Torre:</Text>
          <Paragraph className="interview__answer">
            {interview?.group || 'Não identificada'}
          </Paragraph>
          <Text strong>2. Projeto que atuava</Text>
          <Paragraph className="interview__answer">
            {interview?.project}
          </Paragraph>
          <Text strong>3. Tipo do Desligamento</Text>
          <Paragraph className="interview__answer">
            {people.dismissalType[interview?.type]}
          </Paragraph>
          <Text strong>4. Principal motivo do desligamento</Text>
          <Paragraph className="interview__answer">
            {interview?.reason}
          </Paragraph>
          <Text strong>5. Quem era seu líder na Solutis</Text>
          <Paragraph className="interview__answer">
            {interview?.leader}
          </Paragraph>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 16,
            }}
          >
            <Text strong>6. Como era sua relação com o líder</Text>
            <Rate value={interview?.leaderRelationshipRating} disabled />
          </div>
          <Text strong className="mt-md">
            7. Comentários sobre o líder
          </Text>
          <Paragraph className="interview__answer">
            {interview?.leaderComment}
          </Paragraph>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 16,
            }}
          >
            <Text strong>8. Como classificaria sua jornada na Solutis</Text>
            <Rate value={interview?.journeyRating} disabled />
            <Text strong className="mt-md">
              9. Conhecimento
            </Text>
            <Paragraph className="interview__answer">
              {interview?.knowledge}
            </Paragraph>
            <Text strong className="mt-md">
              10. Como foi sua jornada? (Fale um pouco sobre feedback, avaliação
              de desempenho, capacitação e treinamentos)
            </Text>
            <Paragraph className="interview__answer">
              {interview?.journeyComment}
            </Paragraph>
          </div>
        </Col>
        <Col span={12}>
          <Text strong>11. Para qual empresa está indo?</Text>
          <Paragraph className="interview__answer">
            {interview?.nextCompany}
          </Paragraph>
          <Text strong>12. Qual será o seu salário?</Text>
          <Paragraph className="interview__answer">
            R$ {interview?.salary}
          </Paragraph>
          <Text strong>
            13. Quais serão seus benefícios na nova oportunidade?
          </Text>
          <Paragraph className="interview__answer">
            {interview?.benefits}
          </Paragraph>
          <Text strong>14. Recebeu Contraproposta?</Text>
          <Paragraph className="interview__answer">
            {interview?.counterOffer ? 'Sim' : 'Não'}
          </Paragraph>
          <Text strong>15. Comentários Contraproposta?</Text>
          <Paragraph className="interview__answer">
            {interview?.counterOfferComment}
          </Paragraph>
          <Text strong>
            16. O que acha que devemos manter na Solutis? (O que temos de bom)
          </Text>
          <Paragraph className="interview__answer">
            {interview?.whatIsGood}
          </Paragraph>
          <Text strong>
            17. O que acha que devemos mudar na Solutis? (processos e gestão)
          </Text>
          <Paragraph className="interview__answer">
            {interview?.whatShouldChange}
          </Paragraph>
          <Text strong>18. Você retornaria para a Solutis?</Text>
          <Paragraph className="interview__answer">
            {interview?.wouldYouReturn ? 'Sim' : 'Não'}
          </Paragraph>
          <Text strong>19. E-mail para futuros contatos</Text>
          <Paragraph className="interview__answer">
            {interview?.personalEmail}
          </Paragraph>
          <Text strong>20. Celular para futuros contatos</Text>
          <Paragraph className="interview__answer">
            {interview?.cellphone}
          </Paragraph>
          <Text strong>
            21. Espaço aberto para comentários (elogios, sugestões, críticas)
          </Text>
          <Paragraph className="interview__answer">
            {interview?.otherComments || (
              <p className="soluter-profile__missing-info">Sem comentários.</p>
            )}
          </Paragraph>
          <Text strong>22. Comentários do BP</Text>
          <Paragraph className="interview__answer">
            {interview?.BPComments || (
              <p className="soluter-profile__missing-info">Sem comentários.</p>
            )}
          </Paragraph>
          <Text strong>23. O líder recomenda o Soluter para retorno?</Text>
          <Paragraph className="interview__answer">
            {interview?.leaderRecommendsReturn ? 'Sim' : 'Não'}
          </Paragraph>
          <Text strong>24. Alguma ressalva do líder?</Text>
          <Paragraph className="interview__answer">
            {interview?.leaderCommentAboutUser || (
              <p className="soluter-profile__missing-info">Sem comentários.</p>
            )}
          </Paragraph>
        </Col>
      </Row>
    </Drawer>
  );
}

ExitInterviewDetails.propTypes = {
  interview: PropTypes.object,
  isModalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
};

ExitInterviewDetails.defaultProps = {
  interview: {},
};
