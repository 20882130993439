import { Col, Row, Typography, Rate, Drawer } from 'antd';
import PropTypes from 'prop-types';
import { people } from '~/constants';
import './styles.scss';

const { Text, Paragraph } = Typography;

export default function ExitInvolutaryInterviewDetails({
  interview,
  isModalVisible,
  setModalVisible,
}) {
  return (
    <Drawer
      visible={isModalVisible}
      onClose={() => setModalVisible(false)}
      width="65vw"
      title={`Entrevista de Desligamento: ${interview?.interviewDate}`}
    >
      <Row>
        <Paragraph>Data do Desligamento: {interview?.exitDate}</Paragraph>
      </Row>
      <Row className="interview">
        <Col span={12}>
          <Text strong>1. Nome completo do Ex-Soluter</Text>
          <Paragraph className="interview__answer">{interview?.name}</Paragraph>
          <Text strong>Nome do Entrevistador</Text>
          <Paragraph className="interview__answer">{interview?.BP}</Paragraph>
          <Text strong>BU:</Text>
          <Paragraph className="interview__answer">
            {interview?.practice}
          </Paragraph>
          <Text strong>Centro de custo:</Text>
          <Paragraph className="interview__answer">
            {interview?.team || 'Não identificado'}
          </Paragraph>
          <Text strong>Torre:</Text>
          <Paragraph className="interview__answer">
            {interview?.group || 'Não identificada'}
          </Paragraph>
          <Text strong>2. Nome do Líder</Text>
          <Paragraph className="interview__answer">
            {interview?.leader || (
              <p className="soluter-profile__missing-info">Sem comentários.</p>
            )}
          </Paragraph>
          <Text strong>3. Projeto que atuava</Text>
          <Paragraph className="interview__answer">
            {interview?.project}
          </Paragraph>
        </Col>
        <Col span={12}>
          <Text strong>4. Tipo do Desligamento</Text>
          <Paragraph className="interview__answer">
            {people.dismissalType[interview?.type]}
          </Paragraph>
          <Text strong>5. Principal motivo do desligamento</Text>
          <Paragraph className="interview__answer">
            {interview?.reason}
          </Paragraph>
          <Text strong>6. O líder recomenda o Soluter para retorno?</Text>
          <Paragraph className="interview__answer">
            {interview?.wouldReturn ? <p>Sim</p> : <p>Não</p>}
          </Paragraph>
          <Text strong className="mt-md">
            7. Ressalva do líder:
          </Text>
          <Paragraph className="interview__answer">
            {interview?.leaderComment}
          </Paragraph>
          <Text strong>8. Comentários do BP</Text>
          <Paragraph className="interview__answer">
            {interview?.BPComments || (
              <p className="soluter-profile__missing-info">Sem comentários.</p>
            )}
          </Paragraph>
        </Col>
      </Row>
    </Drawer>
  );
}

ExitInvolutaryInterviewDetails.propTypes = {
  interview: PropTypes.object,
  isModalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
};

ExitInvolutaryInterviewDetails.defaultProps = {
  interview: {},
};
