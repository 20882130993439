/* eslint-disable no-console */
import { decode } from 'jsonwebtoken';
import api from '~/config/httpRequest';
import imageHelper from '~/helpers/imageHelper';
import * as fetchBadges from '~/store/fetchActions/fetchBadges';
import {
  get as getPeople,
  getSoluter,
  updateSoluterPhoto,
  updateSoluterDistrictThumb,
  setSolutersCostCenters,
  setSingleSoluterCostCenter,
  setSoluterDistricts,
  setSoluterProjects,
  setSoluterTowers,
  setSoluterCompetences,
  updateSoluterTowerPhoto,
  getCsvData,
  getExitInterviews,
  updateSoluterBadgePhotoById,
} from '~/store/ducks/people';
import { startLoading, endLoading } from '~/store/ducks/loading';
import { addUser } from '~/store/ducks/user';
import { setUser } from '~/config/environment';
import { getSoluterProjects } from '../ducks/soluterProject';
import { updateTeamMemberPhoto } from '../ducks/towers';

const importantData = ({ name, email }) => ({ name, email });

export const getPeopleProfile = user => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      try {
        user = importantData(decode(user));
        imageHelper
          .getSinglePhoto(`/soluters/users/${user.email}/photo`)
          .then(data => {
            user.photo = data;
            setUser(user);
            dispatch(addUser(user));
          })
          .catch(err => console.log(err));
        resolve(user);
      } catch (error) {
        reject(error);
      }
    });
  };
};

export const get = (page = 1, pageSize = 9, shouldLoadPhotos = true) => {
  return async (dispatch, getState) => {
    dispatch(startLoading('isLoadingSoluters'));
    const state = getState();
    const { selectedCostCenter, selectedPractice } = state.people;
    api
      .get(`/soluters/restricted?term=`, {
        params: {
          page,
          pageSize,
          costCenterId: selectedCostCenter.toString(),
          practiceId: selectedPractice,
        },
      })
      .then(({ data: payload }) => {
        dispatch(endLoading('isLoadingSoluters'));
        dispatch(
          getPeople({
            content: payload?.content || [],
            total: payload?.total || 0,
            page,
          }),
        );
        dispatch(getSoluterCostCenter(payload.content));
        if (payload && shouldLoadPhotos)
          imageHelper.getUserPhotos(
            dispatch,
            payload.content,
            updateSoluterPhoto,
          );
      })
      .catch(err => {
        console.log(err);
        dispatch(getPeople({ content: null, total: 0, page: 1 }));
        dispatch(endLoading('isLoadingSoluters'));
      });
  };
};

export const getByProperty = (
  basicQuery,
  skills = [],
  skillsOperator = 'or',
  seniorities = [],
  leader = '',
  highPotential = '',
  competence = '',
  haveCompetence = false,
  primaryTech = [],
  primaryRole = [],
  notAllocated = false,
  page = 1,
  pageSize = 9,
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedCostCenter, selectedPractice } = state.people;

    dispatch(startLoading('isLoadingSoluters'));
    api
      .get(`/soluters/restricted`, {
        params: {
          term: basicQuery,
          costCenterId: selectedCostCenter.toString(),
          practiceId: selectedPractice,
          page,
          pageSize,
          or_skills: skillsOperator === 'or' ? skills.toString() : '',
          and_skills: skillsOperator === 'and' ? skills.toString() : '',
          or_experiences: skillsOperator === 'or' ? skills.toString() : '',
          and_experiences: skillsOperator === 'and' ? skills.toString() : '',
          seniority: seniorities.toString() || '',
          primaryTech: primaryTech.toString() || '',
          primaryRole: primaryRole.toString() || '',
          notAllocated,
          leader,
          highPotential,
          competence,
          haveCompetence,
        },
      })
      .then(({ data: payload }) => {
        dispatch(endLoading('isLoadingSoluters'));
        dispatch(
          getPeople({
            content: payload?.content || [],
            total: payload?.total || 0,
            page,
          }),
        );
        dispatch(getSoluterCostCenter(payload.content));

        if (payload)
          imageHelper.getUserPhotos(
            dispatch,
            payload.content,
            updateSoluterPhoto,
          );
      })
      .catch(err => {
        console.log(err);
        dispatch(getPeople({ content: null, total: 0, page: 1 }));
        dispatch(endLoading('isLoadingSoluters'));
      });
  };
};

export const getDismissedSoluters = (
  leader,
  highPotential,
  primaryTech,
  primaryRole,
  competence,
  haveCompetence,
  searchTitle,
  page = 1,
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedCostCenter, selectedPractice } = state.people;
    dispatch(startLoading('isLoadingSoluters'));
    api
      .get(`/soluters/journey/users/dismissed-employees`, {
        params: {
          leader,
          highPotential,
          primaryTech: primaryTech?.toString() || '',
          primaryRole: primaryRole.toString() || '',
          competence,
          haveCompetence,
          costCenterId: selectedCostCenter.toString(),
          practiceId: selectedPractice,
          page,
          pageSize: 9,
          term: searchTitle,
        },
      })
      .then(({ data: payload }) => {
        dispatch(endLoading('isLoadingSoluters'));

        dispatch(
          getPeople({
            content: payload?.content || [],
            total: payload?.total || 0,
            page,
          }),
        );
        dispatch(getSoluterCostCenter(payload.content));

        if (payload)
          imageHelper.getUserPhotos(
            dispatch,
            payload.content,
            updateSoluterPhoto,
          );
      })
      .catch(err => {
        console.log(err);
        dispatch(getPeople({ content: null, total: 0, page: 1 }));
        dispatch(endLoading('isLoadingSoluters'));
      });
  };
};

export const getSoluterBasicData = (
  userEmail,
  hasPhoto,
  shouldLoadCostCenter = true,
) => {
  return async dispatch => {
    dispatch(startLoading('isLoadingSoluterBasicData'));
    api
      .get(`/soluters/users/${userEmail}/profile/restricted`)
      .then(async ({ data: payload }) => {
        if (!hasPhoto) {
          try {
            dispatch(startLoading('isLoadingSoluterPhoto'));
            const thumb = await imageHelper.getSinglePhoto(
              `/soluters/users/${userEmail}/photo`,
            );

            payload = { ...payload, thumb };
          } catch (error) {
            payload = { ...payload, thumb: 'no photo' };
          }
          dispatch(endLoading('isLoadingSoluterPhoto'));
        }
        dispatch(endLoading('isLoadingSoluterBasicData'));

        return payload;
      })
      .then(payload => {
        dispatch(getSoluter(payload));
        if (shouldLoadCostCenter)
          dispatch(getSoluterCostCenter(userEmail, true));
      })
      .catch(err => {
        console.log(err);
        dispatch(endLoading('isLoadingSoluterBasicData'));
      });
  };
};

export const getSoluterJourney = userEmail => {
  return async dispatch => {
    dispatch(startLoading('isLoadingSoluterJourney'));
    api
      .get(`/soluters/journey/users/${userEmail}/tracks`)
      .then(({ data: payload }) => {
        dispatch(getSoluter(payload));
        dispatch(endLoading('isLoadingSoluterJourney'));
      })
      .catch(err => {
        console.log(err);
        dispatch(endLoading('isLoadingSoluterJourney'));
      });
  };
};

export function addTechnicalInfo(
  userId,
  {
    workload,
    primaryRole,
    seniority,
    technologies,
    primaryTech,
    infoTechnologies,
  },
) {
  const info = {
    workload,
    primaryRole,
    seniority,
    technologies: infoTechnologies ||
      technologies ||
      primaryTech || ['Não se aplica'],
  };
  return new Promise((resolve, reject) => {
    api
      .put(`/soluters/users/${userId}/technical`, info)
      .then(({ data: res }) => {
        resolve(res);
      })
      .catch(err => reject(err));
  });
}

export const getSoluterHistory = userEmail => {
  return async dispatch => {
    dispatch(startLoading('isLoadingHistory'));
    api
      .get(`/soluters/journey/users/${userEmail}/history`)
      .then(({ data: payload }) => {
        dispatch(getSoluter({ history: payload }));
        dispatch(endLoading('isLoadingHistory'));
      })
      .catch(err => {
        console.log(err);
        dispatch(endLoading('isLoadingHistory'));
      });
  };
};

export const getSoluterCostCenter = (payload, singleUser = false) => {
  const userEmails = singleUser ? [payload] : payload?.map(user => user.email);
  return async dispatch => {
    dispatch(startLoading('isLoadingSolutersCostCenters'));
    api
      .post(`/soluters/journey/users/cost-center`, userEmails || [])
      .then(({ data: _payload }) => {
        if (!singleUser) dispatch(setSolutersCostCenters(_payload));
        else dispatch(setSingleSoluterCostCenter(_payload));
        dispatch(endLoading('isLoadingSolutersCostCenters'));
      })
      .catch(err => {
        console.log(err);
        dispatch(endLoading('isLoadingSolutersCostCenters'));
      });
  };
};

export const getSoluterCompetences = userEmail => {
  return async dispatch => {
    dispatch(startLoading('isLoadingSoluterCompetences'));
    api
      .get(`/soluters/competence/${userEmail}`)
      .then(({ data: payload }) => {
        dispatch(setSoluterCompetences(payload));
        dispatch(endLoading('isLoadingSoluterCompetences'));
      })
      .catch(err => {
        console.log(err);
        dispatch(setSoluterCompetences(null));
        dispatch(endLoading('isLoadingSoluterCompetences'));
      });
  };
};

export const addSoluterCompetence = competence =>
  api.post('/soluters/competence', competence);

export const editSoluterCompetence = (competenceId, competence) =>
  api.put(`/soluters/competence/${competenceId}`, competence);

export const removeSoluterCompetence = competenceId =>
  api.delete(`/soluters/competence/${competenceId}`);

export const editSoluterCompetenceFile = (competenceId, file) => {
  const fileFormData = new FormData();
  fileFormData.append('file', file);

  return api.put(`/soluters/competence/${competenceId}/file`, fileFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteSoluterCompetenceFile = competenceId =>
  api.delete(`/soluters/competence/${competenceId}/file`);

export const getSoluterDistricts = userEmail => {
  return async dispatch => {
    dispatch(startLoading('isLoadingSolutersDistricts'));
    api
      .get(`/districts/user-districts/${userEmail}`)
      .then(({ data: payload }) => {
        dispatch(setSoluterDistricts(payload || []));
        dispatch(endLoading('isLoadingSolutersDistricts'));
        getMultipleItemsPhotos(
          dispatch,
          districtId => `/districts/${districtId}/photo`,
          payload,
          updateSoluterDistrictThumb,
        );
      })
      .catch(err => {
        console.log(err);
        dispatch(setSoluterDistricts(null));
        dispatch(endLoading('isLoadingSolutersDistricts'));
      });
  };
};

export const getSoluterProjectsAndTowers = userEmail => {
  return async dispatch => {
    dispatch(startLoading('isLoadingSolutersProjectsAndTowers'));
    api
      .get(`/rpm/capacity/employee-projects-groups/${userEmail}`)
      .then(({ data: payload }) => {
        dispatch(setSoluterProjects(payload.projects || []));
        dispatch(setSoluterTowers(payload.groups || []));
        dispatch(endLoading('isLoadingSolutersProjectsAndTowers'));
        getMultipleItemsPhotos(
          dispatch,
          towerId => `/rpm/groups/${towerId}/image`,
          payload.groups,
          updateSoluterTowerPhoto,
        );
      })
      .catch(err => {
        console.log(err);
        dispatch(setSoluterProjects(null));
        dispatch(setSoluterTowers(null));
        dispatch(endLoading('isLoadingSolutersProjectsAndTowers'));
      });
  };
};

const getMultipleItemsPhotos = (dispatch, url, items, actionToDispatch) => {
  if (!items) return;
  items?.forEach(async item => {
    let photo;
    try {
      photo = await imageHelper.getSinglePhoto(url(item.id));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(actionToDispatch({ id: item.id, thumb: photo }));
    }
  });
};

export const getUserNames = async (userEmailsList, dispatchAction) => {
  userEmailsList?.forEach(email => {
    api
      .get(`/soluters/users/${email}/profile/restricted`)
      .then(({ data: payload }) => {
        dispatchAction(payload || { name: email, email });
      })
      .catch(err => {
        dispatchAction({ name: email, email });
        console.log(err);
      });
  });
};

export const getSoluterTimeline = userEmail => {
  return async dispatch => {
    dispatch(startLoading('isLoadingTimeline'));
    api
      .get(`/rpm/capacity/${userEmail}/projects`)
      .then(({ data: payload }) => {
        dispatch(getSoluter({ timeline: payload }));
        dispatch(endLoading('isLoadingTimeline'));
      })
      .catch(err => {
        console.log(err);
        dispatch(endLoading('isLoadingTimeline'));
      });
  };
};

export const updateLeadershipOrHighPotential = (
  userEmail,
  fieldName, // "leader" ou "highPotential"
  active,
  dispatch,
) => {
  return new Promise((resolve, reject) => {
    dispatch(startLoading(`isLoading${fieldName}`));
    api
      .put(`/soluters/users/${userEmail}/leadership-highpotential`, {
        [fieldName]: active,
      })
      .then(({ data: payload }) => {
        dispatch(endLoading(`isLoading${fieldName}`));
        resolve(payload);
      })
      .catch(err => {
        dispatch(endLoading(`isLoading${fieldName}`));
        reject(err);
      });
  });
};

export const getSolutersCsv = (
  basicQuery,
  skills = [],
  skillsOperator = 'or',
  seniorities = [],
  leader = '',
  highPotential = '',
  competence = '',
  haveCompetence = false,
  primaryTech = [],
  primaryRole = [],
  notAllocated = '',
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedCostCenter, selectedPractice } = state.people;
    api
      .get(`/soluters/export`, {
        params: {
          term: basicQuery,
          costCenterId: selectedCostCenter.toString(),
          practiceId: selectedPractice,
          or_skills: skillsOperator === 'or' ? skills.toString() : '',
          and_skills: skillsOperator === 'and' ? skills.toString() : '',
          primaryTech: primaryTech.toString() || '',
          primaryRole: primaryRole.toString() || '',
          seniority: seniorities.toString() || '',
          notAllocated,
          leader,
          highPotential,
          competence,
          haveCompetence,
        },
      })
      .then(({ data }) => {
        dispatch(getCsvData(data));
        dispatch(getSoluterCostCenter(data.content));
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const getDismissedCsv = (
  basicQuery,
  skills = [],
  skillsOperator = 'or',
  seniorities = [],
  leader = '',
  highPotential = '',
  competence = '',
  haveCompetence = false,
  primaryTech = [],
  primaryRole = [],
  notAllocated = '',
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { selectedCostCenter, selectedPractice } = state.people;
    api
      .get(`/soluters/journey/users/dismissed-employees/export`, {
        params: {
          term: basicQuery,
          costCenterId: selectedCostCenter.toString(),
          practiceId: selectedPractice,
          or_skills: skillsOperator === 'or' ? skills.toString() : '',
          and_skills: skillsOperator === 'and' ? skills.toString() : '',
          primaryTech: primaryTech.toString() || '',
          primaryRole: primaryRole.toString() || '',
          seniority: seniorities.toString() || '',
          notAllocated,
          leader,
          highPotential,
          competence,
          haveCompetence,
        },
      })
      .then(({ data }) => {
        dispatch(getCsvData(data));
        dispatch(getSoluterCostCenter(data.content));
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const getSoluterExitInterviews = userEmail => {
  return async dispatch => {
    dispatch(startLoading('isLoadingExitInterviews'));
    api
      .get(`/soluters/users/${userEmail}/exit-interview`)
      .then(({ data: payload }) => {
        dispatch(endLoading('isLoadingExitInterviews'));
        dispatch(getExitInterviews(payload));
      })
      .catch(err => {
        console.log(err);
        dispatch(endLoading('isLoadingExitInterviews'));
        dispatch(getExitInterviews(null));
      });
  };
};

export const addSoluterExitInterview = (userEmail, values) => {
  return async dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(startLoading('isAddingExitInterview'));
      api
        .post(`/soluters/users/${userEmail}/exit-interview`, values)
        .then(({ data: payload }) => {
          resolve(payload);
          dispatch(getSoluterExitInterviews(userEmail));
          dispatch(endLoading('isAddingExitInterview'));
        })
        .catch(err => {
          dispatch(endLoading('isAddingExitInterview'));
          reject(err);
        });
    });
  };
};

export const addSoluterExitInterviewInvoluntary = (userEmail, values) => {
  return async dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(startLoading('isAddingExitInterview'));
      api
        .post(`/soluters/users/${userEmail}/exit-interview-involuntary`, values)
        .then(({ data: payload }) => {
          resolve(payload);
          dispatch(getSoluterExitInterviews(userEmail));
          dispatch(endLoading('isAddingExitInterview'));
        })
        .catch(err => {
          dispatch(endLoading('isAddingExitInterview'));
          reject(err);
        });
    });
  };
};

export const updateSoluterExitInterview = (userEmail, interviewId, values) => {
  return async dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(startLoading('isAddingExitInterview'));
      api
        .put(
          `/soluters/users/${userEmail}/exit-interview/${interviewId}`,
          values,
        )
        .then(({ data: payload }) => {
          resolve(payload);
          dispatch(getSoluterExitInterviews(userEmail));
          dispatch(endLoading('isAddingExitInterview'));
        })
        .catch(err => {
          dispatch(endLoading('isAddingExitInterview'));
          reject(err);
        });
    });
  };
};

export const updateExperiences = (userEmail, experiences) => {
  const values = {
    experiencesList: experiences,
  };

  return async dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(startLoading('isUpdatingExperiences'));
      api({
        url: `/soluters/experiences/${userEmail}`,
        method: 'PUT',
        data: values,
      })
        .then(({ data: payload }) => {
          resolve(payload);
          dispatch(getSoluterBasicData(userEmail));
          dispatch(endLoading('isUpdatingExperiences'));
        })
        .catch(err => {
          console.error(err);
          dispatch(endLoading('isUpdatingExperiences'));
          reject(err);
        });
    });
  };
};

export const updateSoluterInvoluntaryExitInterview = (
  userEmail,
  interviewId,
  values,
) => {
  return async dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(startLoading('isAddingExitInterview'));
      api
        .put(
          `/soluters/users/${userEmail}/exit-interview-involuntary/${interviewId}`,
          values,
        )
        .then(({ data: payload }) => {
          resolve(payload);
          dispatch(getSoluterExitInterviews(userEmail));
          dispatch(endLoading('isAddingExitInterview'));
        })
        .catch(err => {
          dispatch(endLoading('isAddingExitInterview'));
          reject(err);
        });
    });
  };
};

export const removeSoluterExitInterview = (userEmail, interviewId) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`/soluters/users/${userEmail}/exit-interview/${interviewId}`)
      .then(({ data: payload }) => {
        resolve(payload);
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
};

export const removeSoluterExitInvoluntaryInterview = (
  userEmail,
  interviewId,
) => {
  return new Promise((resolve, reject) => {
    api
      .delete(
        `/soluters/users/${userEmail}/exit-interview-involuntary/${interviewId}`,
      )
      .then(({ data: payload }) => {
        resolve(payload);
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
};

export const addSoluter = userEmail => {
  return new Promise((resolve, reject) => {
    api
      .get(`/soluters/users/${userEmail}`)
      .then(({ status }) => {
        resolve({ status });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const removeSoluter = userEmail => {
  return new Promise((resolve, reject) => {
    api
      .delete(`/soluters/users/remove/${userEmail}`)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const addCompany = userCompany => {
  return new Promise((resolve, reject) => {
    api
      .post('/soluters/users/insertCompany', userCompany)
      .then(({ status }) => {
        console.log(status);
        resolve({ status });
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
};

export const getBadgePhotoById = badge => {
  return async dispatch => {
    dispatch(startLoading('isLoadingBadgesPhotos'));
    try {
      const thumb = await fetchBadges.getBadgePhoto(badge?.badgeId);
      dispatch(updateSoluterBadgePhotoById({ ...badge, thumb }));
      dispatch(endLoading('isLoadingBadgesPhotos'));
    } catch (e) {
      dispatch(updateSoluterBadgePhotoById({ ...badge, thumb: 'no photo' }));
      dispatch(endLoading('isLoadingBadgesPhotos'));
    }
  };
};

export function getSoluterProjectsList(
  soluterEmail,
  shouldGetMembersPhotos = true,
  year = new Date().getFullYear().toString(),
) {
  let startDate = '';
  let endDate = '';
  if (year) {
    startDate = `01-01-${year}`;
    endDate = `31-12-${year}`;
  }
  return async dispatch => {
    dispatch(startLoading('isLoadingSoluterProjects'));
    api
      .get(`/soluters/users/${soluterEmail}/projects`, {
        params: {
          startDate,
          endDate,
          onlyActive: false,
        },
      })
      .then(({ data: payload }) => {
        dispatch(getSoluterProjects(payload));
        dispatch(endLoading('isLoadingSoluterProjects'));
        if (shouldGetMembersPhotos && payload) {
          payload?.map(project => {
            if (project?.team?.length > 0)
              imageHelper.getUserPhotos(
                dispatch,
                project.team,
                updateTeamMemberPhoto,
                'personKey',
                'photo',
              );
            return project;
          });
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(getSoluterProjects(null));
        dispatch(endLoading('isLoadingSoluterProjects'));
      });
  };
}
