import moment from 'moment';
import { Button, Form, Modal, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as fetchBadges from '~/store/fetchActions/fetchBadges';
import { validationMessages } from '~/constants';
import { TagRender } from '~/components';
import SelectSearchPeoples from '~/components/SelectSearchPeoples/SelectSearchPeoples';

moment.locale('pt-BR');

export default function AddUserForm({ badge, badgeId }) {
  const [isVisible, setVisible] = useState(false);
  const [modal, modalContextHolder] = Modal.useModal();
  const { usersByBadge } = useSelector(state => state.badges);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({});
  }, [form]);

  const handleSubmit = values => {
    const _values = { ...values };
    dispatch(fetchBadges.addUsersToBadge(badgeId, [..._values.soluters]));
    form.resetFields();
    setVisible(false);
  };

  const handleUpdateBadges = () => {
    modal.confirm({
      title: 'Tem certeza que deseja renovar todos os usuários dessa Badge?',
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => {
        dispatch(fetchBadges.UpdateAllUsersInBadge(badgeId));
      },
    });
  };

  const getStatusUsersRenew = () => {
    const expiredList = usersByBadge?.users?.map(
      user => user?.badges?.find(item => item?.badgeId === badgeId)?.expired,
    );

    return expiredList?.includes(true);
  };

  return (
    <>
      {modalContextHolder}
      <div>
        <Button
          type="primary"
          shape="round"
          data-testid="add-new-user-to-badge-button"
          onClick={() => setVisible(true)}
        >
          Associar usuários
        </Button>
      </div>
      <Modal
        title="Associar usuários"
        visible={isVisible}
        onCancel={() => setVisible(false)}
        okText="Associar"
        cancelText="Cancelar"
        okButtonProps={{
          'data-testid': 'add-project-form-submit-button',
        }}
        onOk={() => {
          form
            .validateFields()
            .then(values => handleSubmit(values))
            // eslint-disable-next-line no-console
            .catch(console.error);
        }}
      >
        <Form
          form={form}
          layout="vertical"
          requiredMark
          onFinish={handleSubmit}
          validateMessages={validationMessages.VALIDATION_MESSAGE}
        >
          <Form.Item name="name" required label="Nome / Matrícula">
            <SelectSearchPeoples
              defaultValue={[]}
              fieldLabel="Nome / Matrícula"
              selectMode="multiple"
              tagRenderFunc={props => TagRender(props)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

AddUserForm.propTypes = {
  badge: PropTypes.object.isRequired,
  badgeId: PropTypes.string,
};

AddUserForm.defaultProps = {
  badgeId: '',
};
